import { HydraLogo } from '~/assets/HydraLogo';
import { Outlet } from '@remix-run/react';
export default function AuthLayout() {
  return (
    <div className="flex justify-center items-center h-full p-4">
      <div className="grid grid-cols-1 md:grid-cols-[minmax(auto,30.8rem)_minmax(auto,34.93rem)] gap-4 min-h-[41rem] md:gap-0 shadow-xl">
        <div className="relative bg-gradient-to-b from-[#302B48] to-[#292153] rounded-t-xl md:rounded-l-xl md:rounded-tr-none px-12 py-12 overflow-hidden">
          <div
            className="absolute inset-x-0 top-[15%] h-[70%] w-[70%] opacity-50 pointer-events-none z-10"
            style={{
              borderRadius: '480px',
              background:
                'linear-gradient(16deg, #5A4CFF -15.07%, #5A4CFF 101.62%)',
              filter: 'blur(150px)',
            }}
          />

          <div className="flex justify-between flex-col h-full">
            <div className="pb-10">
              <HydraLogo />
              <h2 className="text-4xl text-white mt-6 z-20 relative">
                <span className="font-bold">Brokkr</span> | Global <br />
                Bare Metal  <br />
                Management for AI
              </h2>
            </div>
            <p className="mt-4 text-sm z-20 relative font-semibold">
              Access top-tier GPU servers worldwide, optimized for AI and HPC workloads. <br /><br />
              The best build on Hydra.
            </p>
          </div>
        </div>

        <div className="bg-[#231F37] px-12 pt-12 pb-10 rounded-b-xl md:rounded-r-xl md:rounded-bl-none flex flex-col">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
